import React from 'react'
import logoPOA from './AlltraChain_logo.png'
import logoPOAWhite from './AlltraChain_logo_white.png'
import { NavLink } from 'react-router-dom'

export const LogoPOA = ({ href = null, extraClass = '', theme = 'dark' }) => {
  return (
    <NavLink to={href} className={`sw-LogoPOA ${extraClass}`}>
      {theme === 'dark' ? (
        <img className="sw-LogoPOA_Image" src={logoPOA} alt="" />
      ) : (
        <img className="sw-LogoPOA_Image" src={logoPOAWhite} alt="" />
      )}
    </NavLink>
  )
}
