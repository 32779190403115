import React from 'react'
import { IconPOA } from '../IconPOA'
import { IconTelegram } from '../IconTelegram'
import { IconTwitter } from '../IconTwitter'

const getIconBackgroundColor = networkBranch => {
  return (
    {
      dai: '#e3e7e9',
      poa: '#fff',
      sokol: '#fff',
      kovan: '#fff',
      alltra: '#007735'
    }[networkBranch] || '#007735'
  )
}

const getIconColor = networkBranch => {
  return (
    {
      dai: '#333',
      poa: '#5c34a2',
      sokol: '#6ac9b9',
      kovan: '#6ac9b9',
      alltra: '#ffffff'
    }[networkBranch] || '#ffffff'
  )
}

export const SocialIcons = ({ extraClass = '', networkBranch = '' }) => {
  const backgroundColor = getIconBackgroundColor(networkBranch)
  const iconColor = getIconColor(networkBranch)

  return (
    <div className={`ft-SocialIcons ${extraClass}`}>
      <IconTwitter
        backgroundColor={backgroundColor}
        color={iconColor}
        text="ALLTRA Twitter"
        url="https://twitter.com"
      />
      <IconTelegram
        backgroundColor={backgroundColor}
        color={iconColor}
        text="ALLTRA Telegram"
        url="https://telegram.org/"
      />
      <IconPOA text="ALLTRA Network" url="https://alltra.global" backgroundColor={backgroundColor} color={iconColor} />
    </div>
  )
}
